import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { SharedModule } from './shared/shared.module';
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { CarouselModule } from '@ciri/ngx-carousel'
import { IndividualConfig, ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from './services/notification.service';

const toastrService = {
  success: (
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ) => {},
  error: (
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ) => {},
};
@NgModule({
  imports: [
    AppRoutingModule,
    SharedModule,
    BrowserModule,
    CarouselModule
  ],
  declarations: [
    AppComponent,
    LandingComponent
  ],
  providers: [
    NgxSpinnerService,
    NotificationService,
    { provide: ToastrService, useValue: toastrService }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }