import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@app/services/api.service';
import { HttpClient, HttpHandler } from '@angular/common/http';
@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
  providers: [ApiService, HttpClient]
})
export class BlankComponent implements OnInit {

  topPosToStartShowing = 100;
  menu: boolean = false;
  show: boolean = false;
  themeshow: boolean = false;
  selectedthme: any = "dark";
  constructor(private apiService: ApiService, private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
    if (localStorage.getItem("theme") != null && localStorage.getItem("theme") != "dark") {
      this.selectedthme = localStorage.getItem("theme") || "dark";
      document.querySelector('body')?.classList.remove('dark');
    }
    else {
      this.selectedthme = 'dark';
      document.querySelector('body')?.classList.add('dark');
    }

    this.apiService.getAll('/get-settings').subscribe((response) => {
      if ((response as any).isSuccess && (response as any).data.length > 0) {
        var settings: any = (response as any).data[0];
        localStorage.setItem("liveTv", settings.TvUrl);
        localStorage.setItem("livemusic", settings.MusicUrl);
        document.getElementById("ImgLiveTV")?.setAttribute("src", settings.ImageUrl)
      }
    });

  }

  onDeactivate() {
    this.show = false;
    this.themeshow = false;
    document.body.scrollTop = 0;
  }

  onNavigate($event: any) {
    window.scroll(0, 0);
  }

  showhide() {
    this.show = !this.show;
  }

  thememenu() {
    this.themeshow = !this.themeshow;
  }

  themechange(theme: string) {
    localStorage.setItem("theme", theme);
    this.selectedthme = theme;
    if (theme == 'dark')
      document.querySelector('body')?.classList.add('dark');
    else
      document.querySelector('body')?.classList.remove('dark');
    this.themeshow = false;
  }

  toggleClass() {
    this.menu = !this.menu;
  }

  menuclick(url: any) {
    this.menu = false;
    this.show = false;
    this.themeshow = false;
    this.router.navigate([url]);
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, scrollable: true }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
