<header class="header">
    <div class="header__content">
        <!-- header logo -->
        <a href="#" class="header__logo">
            <img src="../../../../assets/aasaretv.png" alt="">
        </a>
        <!-- end header logo -->

        <!-- header menu btn -->
        <button class="header__btn" type="button">
            <span></span>
            <span></span>
            <span></span>
        </button>
        <!-- end header menu btn -->
    </div>
</header>
<!-- end header -->
<!-- sidebar -->
<div class="sidebar">
    <!-- sidebar logo -->
    <a [routerLink]="['/admin/dashboard']"  class="sidebar__logo">
        <h3>Admin Portal</h3>
    </a>
    <!-- end sidebar logo -->
    
    <!-- sidebar user -->
    <div class="sidebar__user">
        <div class="sidebar__user-img">
            <img src="../../../../assets/admin/img/user.svg" alt="">
        </div>

        <div class="sidebar__user-title">
            <span>Admin</span>
            <p>{{users.Name}}</p>
        </div>

        <button class="sidebar__user-btn" type="button" (click)="signout()">
            <i class="icon ion-ios-log-out"></i>
        </button>
    </div>
    <!-- end sidebar user -->

    <!-- sidebar nav -->
    <div class="sidebar__nav-wrap">
        <ul class="sidebar__nav">
            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/dashboard']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-keypad"></i> <span>Dashboard</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/schedule']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-film"></i> <span>Schedule</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/testimonal']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-chatbubbles"></i> <span>Testimonies</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/category']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-document"></i> <span>Category</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/Video']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-videocam"></i> <span>Shows</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/song']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-md-musical-note"></i> <span>Video Songs</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/lyrics']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-document"></i> <span>Songs Lyrics</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/quiz']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-checkmark"></i> <span>Quiz Master</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/bible']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-chatbubbles"></i> <span>Bible Quiz</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/live']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-videocam"></i> <span>Live TV</span></a>
            </li>
            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/banner']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-image"></i> <span>Banner</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/prayer']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-contacts"></i> <span>Prayer Request</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/contact']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-md-people"></i> <span>Contact form</span></a>
            </li>
            <li class="sidebar__nav-item">
                <a [routerLink]="['/admin/donate']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-md-calculator"></i> <span>Donate</span></a>
            </li>

            <li class="sidebar__nav-item">
                <a [routerLink]="['/home']" routerLinkActive="active" class="sidebar__nav-link"><i class="icon ion-ios-arrow-round-back"></i> <span>Back to Website</span></a>
            </li>
        </ul>
    </div>
    <!-- end sidebar nav -->
</div>
<!-- end sidebar -->

<!-- main content -->
<router-outlet (deactivate)="onDeactivate()">
</router-outlet>
<!-- end main content -->
