import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var swal: any;


@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

  users :any = {};
  
  constructor( private router : Router) { }

  ngOnInit(): void {
    if(!document.querySelector('body')?.classList.contains("dark"))
    document.querySelector('body')?.classList.add('dark');
    if (localStorage.getItem("s2ftech") == null) {
      this.router.navigateByUrl('/login');
    }
    else{
      this.users = JSON.parse(localStorage.getItem("s2ftech") || '{}');
    }

   
  }

  signout()
  {
    localStorage.removeItem("s2ftech");
    this.router.navigateByUrl('/login');
  }

  onDeactivate() {
    document.body.scrollTop = 0;
  }


}
